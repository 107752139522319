.text-pauta{
    align-items:center !important;
    text-align:center !important;
}
.image-pauta{
    display:flex;
    justify-content:'center';
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
}
.img-pauta-view-tv{
    margin: auto;
    padding: auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.image-pauta-redondeada{
    border-radius: 50%;
    
}
.template-text-center{
    text-align: center;
    width: 100%;
}
.contendor-templete{
    background-color: blue;
}



.hexagon {
    margin-left: -44%;
    width: 200%;
    height: 100%;
    background-color: #ffffff;
    clip-path: polygon(
        50% 0%,
        75% 25%,
        75% 75%,
        50% 100%,
        25% 75%,
        25% 25%
    );
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.image-container {
    width: 90%;
    height: 90%;
    background-size: cover;
    background-position: center;
    clip-path: polygon(
        50% 0%,
        75% 25%,
        75% 75%,
        50% 100%,
        25% 75%,
        25% 25%
    );
}

